import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { AuthenticationInterceptor } from './core/authentication/authentication.interceptor';
import './core/prototypes/number-prototypes';
import { RouteReuseService } from './core/routes/routereuse.service';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            progressBar: true,
            extendedTimeOut: 5000,
            tapToDismiss: true,
            maxOpened: 3,
            preventDuplicates: true
        }),
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
    ]
    ,
    providers: [
        {
            provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true
        },
        {
            provide: RouteReuseStrategy, useClass: RouteReuseService
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
