
import {filter,  takeUntil } from 'rxjs/operators';
import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { SettingsService, userTypes, userRoles } from './core/settings/settings.service';
import { ToastrService } from 'ngx-toastr';




import { EventsService } from './core/events/events.service';
import { Subject } from 'rxjs';
//import { AuthenticationService } from './core/authentication/authentication.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @HostBinding('class.layout-fixed') get isFixed() { return this.settings.layout.isFixed; };
  @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.layout.isCollapsed; };
  @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.layout.isBoxed; };
  @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.layout.useFullLayout; };
  @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.layout.hiddenFooter; };
  @HostBinding('class.layout-h') get horizontal() { return this.settings.layout.horizontal; };
  @HostBinding('class.aside-float') get isFloat() { return this.settings.layout.isFloat; };
  @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.layout.asideToggled; };
  @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.layout.isCollapsedText; };


  private onDestroy$: Subject<void> = new Subject<void>();
  private initialCustomerChanged: boolean = false;

  public notificationSettings = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  }

  constructor(
    public settings: SettingsService,
    public toastrService: ToastrService,
    public eventsService: EventsService
    //public authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    $(document).on('click', '[href="#"]', e => e.preventDefault());
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  // appCustomerChanged() {
  //   var self = this;
  //   setTimeout(() => {
  //       self.eventsService.changeMessage("customerChanged");
  //   }, 1500);
  // }

}
